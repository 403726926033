// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  'globals.locale': 'se',
  'globals.signIn': 'Logga in',
  'globals.logIn': 'Logga in',
  'globals.signUp': 'Registrera dig',
  'globals.signOut': 'Logga ut',
  'globals.logOut': 'Logga ut',
  'globals.submit': 'Skicka',
  'globals.submitting': 'Skickas…',
  'globals.yourEmail': 'Din e-postadress',
  'globals.yourPassword': 'Ditt lösenord',
  'globals.courseOverview': 'Kursbeskrivning',
  'globals.forgotPassword': 'Glömde du ditt lösenord?',
  'globals.invalidCredentials': 'Fel i inloggningsdata',
  'globals.section': 'Avsnitt',
  'globals.exercises': 'Uppgift',
  'globals.chapter': 'Kapitel',
  'globals.introToAI': 'Grunderna i artificiell intelligens',
  'globals.buildingAI': 'Bygga AI',
  'globals.acceptPolicy': 'Accept policy',
  // section
  'section.footerEndOfTheChapter': 'Du har avklarat kapitlet!',
  'section.footerEndOfTheCourseTitle': 'Du har avklarat kursen!',
  'section.footerEndOfTheCourseText':
    'Vi bedömer dina svar när du har slutfört alla uppgifter och gett dina egna bedömningar på dina kurskamraters texter.',
  'section.nextSection': 'Nästa avsnitt',
  'section.nextChapter': 'Nästa kapitel',
  'section.start': 'Börja',
  'section.logisticRegressionExample.slope': 'Lutning',
  'section.logisticRegressionExample.intercept': 'Avlyssna',
  'section.footerEndOfTheCourseBAI': 'Fortsätt din resa mot att kunna implementera din första AI-idé.',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Väntar på din kollegiala bedömning',
  'quiz.rejectedPeerReview': 'Underkänd i kollegial bedömning',
  'quiz.unsubmittedAnswer': 'Svaret har inte skickats',
  'quiz.pendingPeerReview': 'Väntar på kollegial bedömning',
  // quiz
  'quiz.status.answered': 'Besvarad',
  'quiz.status.rejected': 'Svaret icke godkänt, försök igen',
  'quiz.status.unanswered': 'Obesvarad',
  'quiz.status.exercisesCompleted': 'Antal genomförda uppgifter',
  'quiz.status.correctAnswers': 'Antal godkända svar',
  'quiz.message.rejectedInReview':
    'Ditt svar har inte godkänts i den kollegiala bedömningen. Var god och försök igen.',
  'quiz.youAnswered': 'Du svarade',
  'quiz.yourAnswer': 'Ditt svar',
  'quiz.yourScore': 'Dina poäng',
  'quiz.quizNotLoaded': 'Uppgiften kunde inte laddas',
  'quiz.words': 'Antal ord',
  'quiz.exampleAnswer': 'Exempelsvar',
  // peer review
  'peerreview.question1': 'Svaret är sakligt',
  'peerreview.question2': 'Svaret är omfattande',
  'peerreview.question3': 'Svaret är väl grundat',
  'peerreview.question4': 'Svaret är lätt att förstå',
  'peerreview.thankYou': 'Tack för att du bedömde andras svar!',
  'peerreview.giveMoreReviews': 'Om du har tid',
  'peerreview.giveMoreReviewsButton': 'kan du ge fler bedömningar',
  'peerreview.notEnoughAnswers':
    'Tyvärr har inte tillräckligt många svar lämnats in för dig att kunna värdera. Var god och kom tillbaka senare.',
  'peerreview.selectAnswerMessage':
    'Det är dags att ge kollegial bedömning! Nedan hittar du andra kursdeltagares svar. Välj ett och bedöm svaret. Upprepa detta minst tre gånger.',
  'peerreview.minimumPeerReviewWarning':
    'Uppgiften kan godkännas när du har gett tre kollegiala bedömningar.',
  'peerreview.select': 'välj',
  'peerreview.reportSpam': 'Meddela om osakligt svar.',
  'peerreview.feedback':
    'Ditt svar har bedömts och godkänts. Nedan hittar du bedömningen på ditt svar:',
  // exercise17
  'quiz.exercise17.xLabel': 'utbildning (i antal år)',
  'quiz.exercise17.yLabel': 'förväntad livslängd (i antal år)',
  'quiz.exercise17.textPart1':
    'Vi ska granska sambandet mellan tid i skolan (från förskola till universitet och allt där emellan) och förväntad livslängd. Vi har data från tre olika länder och de framställs som tre olika punkter.',
  'quiz.exercise17.textPart2':
    'I våra data finns det ett land där man i medeltal går i skolan 10 år och den förväntade livslängden är 57 år. I ett annat land går man i skola 13 år och den förväntade livslängden är 53 år. I det tredje landet går man i skola i 20 år och den förväntade livslängden är 80 år.',
  'quiz.exercise17.textPart3':
    'Genom att klicka och dra på ändpunkterna på linjen i diagrammet, kan du justera diagrammet så att det visar olika prognoser. Försök att hitta en linje som beskriver data på bästa möjliga sätt. Det går inte att överlappa alla tre punkter helt exakt med det gör inget. En del av punkterna kan hamna lite över linjen och en del lite under. Huvudsaken är att linjen avbildar data i stora drag.',
  'quiz.exercise17.textPart4':
    'När du är nöjd med din linje, kan du använda den för att förutspå livslängden.',
  'quiz.exercise17.textPart5':
    'Utifrån våra tillgängliga data, vad kan du säga om den förväntade livslängden för personer som har gått 15 år i skola? Obs! Trots att du kan få en ganska exakt prognos genom att dra på linjen, kan du inte helt lita på den. Ta hänsyn till mängden data när du väljer ditt svar.',
  // exercise18
  'quiz.exercise18.xLabel': 'utbildning (i antal år)',
  'quiz.exercise18.yLabel': 'förväntad livslängd ( i antal år)',
  'quiz.exercise18.textPart1':
    'I föregående uppgift hade vi enbart data från tre länder. En större mängd data presenteras i följande diagram. Det innehåller data från 14 länder.',
  'quiz.exercise18.textPart2':
    'Förändras din prognos för personer som har gått i skola i 15 år nu när datagruppen är större? Varför?',
  'quiz.exercise18.textPart3':
    'Vilket av följande alternativ beskriver bäst din uppskattning av den förväntade livslängden för personer som har gått i skola i 15 år? Välj det mest exakta alternativet som du kan argumentera för genom att passa in linjen på data ovan. Ta hänsyn till att det är mer data än tidigare, men att mängden fortfarande är begränsad.',
  // exercise19
  'quiz.exercise19.xLabel': 'tid använd för förberedelse (timmar)',
  'quiz.exercise19.yLabel': 'sannolikheten att bli godkänd',
  'quiz.exercise19.textPart1':
    'Varje punkt representerar en deltagare. I nedre kanten av diagrammet ser du en skala på hur mycket deltagarna har lagt ner tid på inlärningen. Punkterna i nedre kanten är deltagare som inte blev godkända och punkterna i övre kanten deltagare som blev godkända. Med hjälp av skalan till vänster i diagrammet kan vi ange sannolikheten för att deltagaren ska få godkänt för kursen på basis av antalet timmar hen använt för att förbereda sig. Sannolikheten får vi från genom logistiska regressionsmodellen, vilket vi snart ska förklara.',
  'quiz.exercise19.textPart2':
    'Genom att granska diagrammet märker du säkert att de deltagare som använt mer tid till att förbereda sig, hade bättre chans att bli godkända i testet än de som bara förberedde sig några timmar. Ytterlighetsfallen är lätta att förstå: det är väldigt svårt att bli godkänd i testet med en timmes förberedelse, men troligtvis är det lättare ju mer tid man lägger ner. Men vad kan vi säga om dem som befinner sig i mitten på skalan? Vilken sannolikhet är det att bli godkänd i testet om man förbereder sig sex timmar?',
  'quiz.exercise19.textPart3':
    'Logistisk regression kan producera en uppskattning på hur sannolikt det är att deltagaren blir godkänd i testet. Kurvan som liknar ett S avbildar detta: med fem timmars förberedelse har man 20 procents chans att bli godkänd i testet. Vi ska inte gå in på hur man gör en sådan här kurva, men den baseras på liknande koefficienter som de som används i linjär regression.',
  'quiz.exercise19.textPart4':
    'Hur många timmar måste du enligt diagrammet ovan förbereda dig om du vill bli godkänd med 80 procents sannolikhet på en matlagningskurs?',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Indata',
  'quiz.exercise22.sigmoidYLabel': 'sigmoidfunktionens utdata',
  'quiz.exercise22.stepXLabel': 'Indata',
  'quiz.exercise22.stepYLabel': 'stegfunktionens utdata',
  'quiz.exercise22.identityXLabel': 'Indata',
  'quiz.exercise22.identityYLabel': 'identitetsfunktionen utdata',
  'quiz.exercise22.textPart1':
    'De tre diagram som du ser nedan föreställer tre olika aktiveringsfunktioner. Den första är en sigmoidfunktion, den andra stegfunktion och den tredje identitetsfunktion. VIKTIGT: Observera den annorlunda skalan på y-axeln (vertikal) i diagrammet över identitetsfunktionen.',
  // exercise23
  'quiz.exercise23.happyFaces': 'av de glada smilisarna klassificerade rätt',
  'quiz.exercise23.sadFaces': 'av de ledsna smilisarna klassificerade rätt',
  // certificate availability
  'certificate.failedToCheck': 'Fel vid granskning av certifikatet',
  'certificate.failedToGenerate': 'Fel vid skapande av certifikatet',
  'certificate.notYetAvailable':
    'Ditt certifikat är tillgängligt här efter den officiella bekräftelsen. Detta kan ta en stund. Tack för ditt tålamod!',
  'certificate.updateName':
    'Ditt certifikat finns nu tillgängligt! Var vänlig och uppdatera ditt för- och efternamn så att det syns på ditt certifikat med hjälp av ett formulär som finns på din profilsida.',
  'certificate.generate': 'Skapa ditt certifikat här.',
  'certificate.generateTextPart1': 'Certifikatet skapas på namnet',
  'certificate.generateTextPart2':
    'Kontrollera att uppgifterna är korrekta innan du skapar ditt certifikat. Namnet kan inte ändras i efterhand. Om du vill ändra ditt namn, gör det via blanketten nedan.',
  'certificate.generateButton': 'Skapa certifikatet',
  'certificate.downloadButton': 'Ladda certifikatet',
  'certificate.checkingAvailability':
    'Kontrollerar certifikatets tillgänglighet…',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly':
    'Rätt klassificerad',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly':
    'Fel klassificerad',
  // section blocks
  'section.block.note': 'Obs',
  'section.block.example': 'Exempel',
  'section.block.keyTerminology': 'Nyckelbegrepp',
  'section.partSummary.spectrumTextPart1':
    'Ta del i ',
  'section.partSummary.spectrum': 'Elements of AI -gemenskapen',
  'section.partSummary.spectrumTextPart2':
    ' för att diskutera och fråga om innehåll i det här kapitlet.',
  'section.partSummary.feedbackTitle': 'Ge respons på det här kapitlet',
  'section.partSummary.feedbackPlaceholder': 'Skriv din respons här.',
  'section.partSummary.feedbackSuccess': 'Tack för din respons!',
  // 404
  'globals.404.title': '404 – sidan hittas ej',
  'globals.404.intro': 'Oj! Du hittade en intet existerande rutt.',
  'globals.loading': 'Laddas….',
  // footer / menu
  'globals.menu': 'Meny',
  'globals.menu.about': 'Information',
  'globals.menu.faq': 'Frågor och svar',
  'globals.menu.privacyPolicy': 'Dataskyddsbeskrivning',
  'globals.menu.myProfile': 'Min profil',
  'globals.menu.termsofService': 'Användarvillkor',
  // Dashboard/Index
  'dashboard.title': 'Välkommen till kursen!',
  'dashboard.congratulations.title': 'Grattis!',
  'dashboard.congratulations.message':
    'Du har nu avklarat kursen Elements of AI – Grunderna i artificiell intelligens!',
  'dashboard.congratulations.downloadCertificate': 'Ladda certifikatet',
  'dashboard.notLoggedIn.title': 'Välkommen!',
  'dashboard.notLoggedIn.message':
    'Registrera dig eller logga in för att se hela kursmaterialet. Kursen är gratis!',
  // Account
  'account.certificate': 'Certifikat',
  'account.myProfile': 'Konto',
  'account.credits': 'Studiepoäng',
  'account.changePassword': 'Byt lösenord',
  'account.changeLanguage': 'Byt språk',
  'account.profileInformation': 'Profilinformation',
  'account.goToCourse': 'Gå till kursen',
  'account.buildingAINew': 'Ny kurs på engelska',
  'account.accept': 'Jag accepterar',
  'account.decline': 'Jag accepterar inte',
  'account.courses': 'Kurser',
  // Sign Up page
  'signUp.description':
    'Skapa ett nytt mooc.fi-konto genom att fylla i uppgifterna nedan',
  'signUp.email': 'Din e-postadress',
  'signUp.firstName': 'Ditt förnamn',
  'signUp.lastName': 'Ditt efternamn',
  'signUp.postalCode': 'Postnummer',
  'signUp.country': 'Land',
  'signUp.Password': 'Lösenord',
  'signUp.confirmPassword': 'Upprepa lösenord',
  'signUp.howToStudyTitle': 'Hur vill du studera?',
  'signUp.6weeksTitle': 'Jag går kursen på sex veckor',
  'signUp.6weeksSubTitle': 'Rekommenderat (ca 5 timmar per vecka)',
  'signUp.6weeksText':
    'Den angivna tidtabellen hjälper dig att genomföra kursen.',
  'signUp.selfPacedTitle': 'Ej tidsbunden',
  'signUp.selfPacedText': 'Du kan gå kursen i egen takt.',
  'signUp.ectsTitle':
    'Tänker du ansöka om studiepoäng? Välj ett av alternativen:',
  'signUp.ectsTitleSwe': 'Vill du ansöka om högskolepoäng?',
  'signUp.ectsStudent':
    'Jag studerar vid Helsingfors universitet. Om du väljer det här alternativet och anger ditt studienummer, får du studiepoängen automatiskt.',
  'signUp.ectsStudentSwe':
    'Efter att ha gått kursen till slut kan du här ansöka om högskolepoäng från Linköpings universitet. Du kan hitta instruktionerna ',
  'signup.ectsStudentSweLink': ' här.',
  'signUp.ectsOpenUni':
    'Jag vill ha studiepoäng från Helsingfors universitet. Om du väljer det här alternativet får du instruktioner från Öppna universitetet till din e-post efter att du genomfört kursen.',
  'signUp.studentNumber': 'Ditt studienummer',
  'signUp.privacyOptIn':
    'Jag ger tillstånd att samla in information om mina kursprestationer för forskningssyfte. Den insamlade informationen handlar om användning av material och kursuppgifter. Enstaka kursdeltagare kan inte urskiljas från resultaten.',
  'signUp.marketingOptIn':
    'Jag vill bli kontaktad för information om nya kurser. Jag samtycker till att dela min kontaktinformation för att få skräddarsydda meddelanden på tredjepartsplattformar. Läs mer i vår dataskyddsbeskrivning.',
  'signUp.privacyLink': 'Dataskyddsbeskrivning',
  'signUp.error': 'Det finns fel i formuläret. Vänligen korrigera felen.',
  'signUp.acceptPolicyFirst': 'Jag accepterar dataskyddsbeskrivningen och användarvillkoren.',
  // Sign in page
  'signIn.title': 'Logga in',
  'signIn.intro': 'Logga in med ditt mooc.fi-konto',
  'signIn.email': 'Din e-postadress',
  'signIn.password': 'Ditt lösenord',
  'signIn.noAccount': 'Har du inget konto?',
  'signIn.signUpNow': 'Registrera dig nu',
  // Forgot password
  'pwForget.title': 'Glömt ditt lösenord?',
  'pwForget.description':
    'Ange den e-postadressen som du har på ditt mooc.fi-konto, dit vi skickar en länk för att nollställa lösenordet.',
  'pwForget.sendResetLink': 'Skicka länk för att nollställa lösenordet',
  'pwForget.completedDescription':
    'Du får strax ett e-postmeddelande från mooc.fi med en länk för att nollställa ditt lösenord. Kontrollera din skräppost mapp, om du inte får någon e-post från oss.',
  'pwForget.problemDescription':
    'Vid problemfall, ta kontakt med mooc@cs.helsinki.fi.',
  'pwForget.resetEmailSent':
    'Länken för nollställning av lösenord har nu skickats',
  'pwForget.emailPlaceholder': 'E-postadress',
  // password change
  'pwChange.authError':
    'Identifieringen misslyckades. Försök att logga in igen.',
  'pwChange.unexpError':
    'Uppdatering av lösenord misslyckades av okänd anledning',
  'pwChange.required': 'Krävs',
  'pwChange.pwMustMatch': 'Lösenorden måste vara desamma',
  'pwChange.currentPassword': 'Nuvarande lösenord',
  'pwChange.newPassword': 'Nytt lösenord',
  'pwChange.confirmPassword': 'Bekräfta nytt lösenord',
  'pwChange.changingPassword': 'Lösenordet byts',
  'pwChange.changePassword': 'Byt lösenord',
  'pwChange.passwordChanged': 'Lösenordet är nu bytt',
  // user details
  'userDetails.authError':
    'Identifieringen misslyckades. Försök att logga in igen.',
  'userDetails.unexpError':
    'Uppdatering av användaruppgifter misslyckades av okänd anledning',
  'userDetails.required': 'Krävs',
  'userDetails.invalidEmail': 'Fel e-postadress',
  'userDetails.email': 'E-post',
  'userDetails.emailAddress': 'E-postadress',
  'userDetails.firstName': 'Förnamn',
  'userDetails.lastName': 'Efternamn',
  'userDetails.language': 'Språk',
  'userDetails.updatingProfile': 'Profilen uppdateras',
  'userDetails.updateProfile': 'Uppdatera profilen',
  'userDetails.profileUpdated': 'Profilen är uppdaterad',
  // languages
  'languages.en.long': 'Engelska',
  'languages.fi.long': 'Finska',
  'languages.se.long': 'Svenska',
  'languages.de.long': 'Tyska (Tyskland)',
  'languages.ee.long': 'Estniska',
  'languages.it.long': 'Italienska',
  'languages.fr.long': 'Franska (Frankrike)',
  'languages.no.long': 'Norska',
  'languages.sl.long': 'Slovenska',
  'languages.el.long': 'Grekiska',
  'languages.pt.long': 'Portugisiska',
  'languages.sk.long': 'Slovakiska',
  'languages.nl.long': 'Nederländska (Nederländerna)',
  'languages.cs.long': 'Tjeckiska',
  'languages.lv.long': 'Lettiska',
  'languages.da.long': 'Danska',
  'languages.ro.long': 'Rumänska',
  'languages.pl.long': 'Polska',
  'languages.en-ie.long': 'Engelska (Irland)',
  'languages.mt.long': 'Maltesiska',
  'languages.fr-be.long': 'Franska (Belgien)',
  'languages.lt.long': 'Litauiska',
  'languages.ga.long': 'Iriska',
  'languages.nl-be.long': 'Nederländska (Belgien)',
  'languages.hr.long': 'Kroatiska',
  'languages.is.long': 'Isländska',
  'languages.de-at.long': 'Tyska (Österrike)',
  'languages.en-lu.long': 'Engleska (Luxemburg)',
  'languages.bg.long': 'Bulgariska',
  'languages.es.long': 'Spanska',
  // Email verfification
  'emailVerification.completedMessage': 'Din e-postadress är bekräftad',
  'emailVerification.verified': 'Bekräftat',
  // Exercises
  'exercise.loading': 'Uppgiften laddas ner',
  'exercise.signUp': 'Registrera dig för att svara på uppgifterna',
  'exercise.score': 'Dina poäng',
  'exercise.wrongLocale': 'Fel språkområde',
  // Part tempplate
  'partTemplate.intro1': 'Kapitel ',
  'partTemplate.intro2': ' omfattar följande avsnitt. Välj ett avsnitt nedan:',
  'partTemplate.spectrumPart1': 'Ta del i ',
  'partTemplate.spectrum': 'Elements of AI-gemenskapen',
  'partTemplate.spectrumPart2':
    ' för att diskutera och ställa frågor om innehållet i det här kapitlet.',
  // missing info
  'missingInfo.title': 'Uppgifter saknas',
  'missingInfo.body':
    'Oftas ber vi om dessa uppgifter vid registrering, men du registrerade dig med ett existerade mooc.fi-konto. Vänligen fyll i uppgifterna för att gå vidare.',
  'missingInfo.privacyPolicy': 'Dataskyddsbeskrivning',
  'missingInfo.error':
    'Det finns fel i formuläret. Vänligen kontrollera uppgifterna.',
  'missingInfo.privacyPolicyUpdateBody': "Vi har uppdaterat vår integritetspolicy. Här är en sammanfattning av ändringarna. Vi annonserar våra kurser för potentiella nya studenter genom att bilda publik i marknadsföringsplattformar. Detta kan innebära att vi delar dina e-postadresser med dessa plattformar. För samma ändamål kommer anonymiserad aktivitetsinformation att delas med marknadsföringsnätverk. Du kan förhindra delning av e-postinformation genom att avmarkera kryssrutan för marknadsföringssamtycke i din kursprofil. För att förhindra delning av aktivitetsdata kan du ändra cookie-inställningarna på kursens webbplats. Att acceptera dessa villkor krävs för att fortsätta använda tjänsten.",
  'missingInfo.privacyPolicyUpdateLink': 'Läs vår dataskyddsbeskrivning här.',
  // routes
  'routes.LANDING': '/se/',
  'routes.SIGN_UP': '/se/signup',
  'routes.SIGN_IN': '/se/signin',
  'routes.MISSING_INFO': '/se/missinginfo',
  'routes.PASSWORD_FORGET': '/se/pw-forget',
  'routes.HOME': '/se/home',
  'routes.ACCOUNT': '/se/account',
  'routes.PRIVACY': 'https://www.elementsofai.se/faq/dataskyddsf%C3%B6rordning',
  'routes.FAQ': 'https://www.elementsofai.se/faq',
  'routes.ABOUT': 'https://www.elementsofai.se/',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/se-svenska',
  'routes.ects': 'https://mooc.fi/se/register-completion/elements-of-ai',
  'routes.SWECREDIT': 'https://liu.se/utbildning/kurs/ete318',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  'routes.ects.faq': 'https://www.elementsofai.se/faq/f%C3%A5r-man-studiepo%C3%A4ng-f%C3%B6r-kursen',
  'routes.ACCEPT_POLICY': '/se/accept-policy',

  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Utomhustemperatur (°C)',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Glassar sålda per dag',
  // meta
  'meta.title': 'Gratis online-kurs',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description':
    'Läs mer om Helsingfors universitets och Reaktors kurs i artificiell intelligens för både studerande och professionella – den kräver inga kunskaper i programmering eller komplex matematik.',
  'meta.sitename': 'Elements of AI',
  'meta.title.og': 'A free online introduction to artificial intelligence for non-experts',
  // ECTS
  'ects.text':
    'Instruktioner för att ansöka om studiepoäng kommer att visas här efter att du genomfört kursen.',
  'ects.textCompleted':
    'Om du har en finländsk personbeteckning kan du ansöka om studiepoäng genom att klicka ',
  'ects.link': 'här',
  'ects.info': 'Tänker du ansöka om studiepoäng?',
  'ects.readMore': 'Läs mer',

  // Countries
  'countryselect.select': 'Please select',
  'Finland': 'Finland',
  'Estonia': 'Estland',
  'Germany': 'Tyskland',
  'Sweden': 'Sverige',
  'France': 'Frankrike',
  'Italy': 'Italien',
  Countries: {
    Afganistan: 'Afghanistan',
    ÅlandIslands: 'Åland',
    Albania: 'Albanien',
    Algeria: 'Algeriet',
    AmericanSamoa: 'Amerikanska Samoa',
    Andorra: 'Andorra',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antarktis',
    AntiguaAndBarbuda: 'Antigua och Barbuda',
    Argentina: 'Argentina',
    Armenia: 'Armenien',
    Aruba: 'Aruba',
    Australia: 'Australien',
    Austria: 'Österrike',
    Azerbaijan: 'Azerbajdzjan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahrain',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbados',
    Belarus: 'Belarus',
    Belgium: 'Belgien',
    Belize: 'Belize',
    Benin: 'Benin',
    Bermuda: 'Bermuda',
    Bhutan: 'Bhutan',
    Bolivia: 'Bolivia',
    BosniaAndHerzegovina: 'Bosnien och Hercegovina',
    Botswana: 'Botswana',
    BouvetIsland: 'Bouvet Island',
    Brazil: 'Brasilien',
    BritishIndianOceanTerritory: 'Brittiska territoriet i Indiska oceanen',
    BruneiDarussalam: 'Brunei Darussalam',
    Bulgaria: 'bulgarien',
    BurkinaFaso: 'Burkina Faso',
    Burundi: 'Burundi',
    Cambodia: 'Kambodja',
    Cameroon: 'Kamerun',
    Canada: 'Kanada',
    CapeVerde: 'Cap Verde',
    CaymanIslands: 'Caymanöarna',
    CentralAfricanRepublic: 'Centralafrikanska republiken',
    Chad: 'Chad',
    Chile: 'Chile',
    China: 'Kina',
    ChristmasIsland: 'Julön',
    'Cocos(Keeling)Islands': 'Cocosöarna',
    Colombia: 'Colombia',
    Comoros: 'Komorerna',
    Congo: 'Kongo',
    CongoTheDemocraticRepublicOfThe: 'Kongo, Demokratiska republiken The',
    CookIslands: 'Cooköarna',
    CostaRica: 'Costa Rica',
    "CoteD'ivoire": "Cote D'ivoire",
    Croatia: 'Kroatien',
    Cuba: 'Kuba',
    Cyprus: 'Cypern',
    CzechRepublic: 'Tjeckien',
    Denmark: 'Danmark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominica',
    DominicanRepublic: 'Dominikanska republiken',
    Ecuador: 'Ecuador',
    Egypt: 'Egypten',
    ElSalvador: 'El Salvador',
    EquatorialGuinea: 'Ekvatorialguinea',
    Eritrea: 'Eritrea',
    Estonia: 'Estland',
    Ethiopia: 'Etiopien',
    'FalklandIslands(Malvinas)': 'Falklandsöarna (Malvinas)',
    FaroeIslands: 'Färöarna',
    Fiji: 'Fiji',
    Finland: 'Finland',
    France: 'Frankrike',
    FrenchGuiana: 'Franska Guyana',
    FrenchPolynesia: 'Franska Polynesien',
    FrenchSouthernTerritories: 'Franska södra territorier',
    Gabon: 'Gabon',
    Gambia: 'Gambia',
    Georgia: 'Georgien',
    Germany: 'Tyskland',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Grekland',
    Greenland: 'Grönland',
    Grenada: 'Grenada',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernsey',
    Guinea: 'Guinea',
    GuineaBissau: 'Guinea-Bissau',
    Guyana: 'Guyana',
    Haiti: 'Haiti',
    HeardIslandAndMcdonaldIslands: 'Heard- och McDonaldöarna',
    'HolySee(VaticanCityState)': 'Heliga Stolen (Vatikanstaten)',
    Honduras: 'Honduras',
    HongKong: 'Hong Kong',
    Hungary: 'Ungern',
    Iceland: 'Island',
    India: 'Indien',
    Indonesia: 'Indonesien',
    IranIslamicRepublicOf: 'Iran, Islamiska republiken',
    Iraq: 'Irak',
    Ireland: 'Irland',
    IsleOfMan: 'Isle of Man',
    Israel: 'Israel',
    Italy: 'Italien',
    Jamaica: 'Jamaica',
    Japan: 'Japan',
    Jersey: 'Jersey',
    Jordan: 'Jordanien',
    Kazakhstan: 'Kazakstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    KoreaDemocraticPeoplesRepublicOf: 'Korea, Demokratiska folkrepubliken',
    KoreaRepublicOf: 'Korea, Republiken av',
    Kosovo: 'Kosovo',
    Kuwait: 'Kuwait',
    Kyrgyzstan: 'Kirgizistan',
    LaoPeoplesDemocraticRepublic: 'Demokratiska folkrepubliken Laos',
    Latvia: 'Lettland',
    Lebanon: 'Libanon',
    Lesotho: 'Lesotho',
    Liberia: 'Liberia',
    LibyanArabJamahiriya: 'Libyska Arab Jamahiriya',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Litauen',
    Luxembourg: 'Luxemburg',
    Macao: 'Macao',
    MacedoniaTheFormerYugoslavRepublicOf:
      'Makedonien, fd jugoslaviska republiken',
    Madagascar: 'Madagaskar',
    Malawi: 'Malawi',
    Malaysia: 'Malaysia',
    Maldives: 'Maldiverna',
    Mali: 'Mali',
    Malta: 'Malta',
    MarshallIslands: 'Marshallöarna',
    Martinique: 'Martinique',
    Mauritania: 'Mauretanien',
    Mauritius: 'Mauritius',
    Mayotte: 'Mayotte',
    Mexico: 'Mexico',
    MicronesiaFederatedStatesOf: 'Mikronesien, Förenta staterna',
    MoldovaRepublicOf: 'Moldavien, Republiken',
    Monaco: 'Monaco',
    Mongolia: 'Mongoliet',
    Montenegro: 'Montenegro',
    Montserrat: 'Montserrat',
    Morocco: 'Marocko',
    Mozambique: 'Moçambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibia',
    Nauru: 'Nauru',
    Nepal: 'Nepal',
    Netherlands: 'Nederländerna',
    NetherlandsAntilles: 'Nederländska Antillerna',
    NewCaledonia: 'Nya Kaledonien',
    NewZealand: 'Nya Zeeland',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    Niue: 'Niue',
    NorfolkIsland: 'Norfolk Island',
    NorthernMarianaIslands: 'Norra Marianöarna',
    Norway: 'Norge',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palau',
    PalestinianTerritoryOccupied: 'Palestinska territoriet, ockuperat',
    Panama: 'Panama',
    PapuaNewGuinea: 'Papua Nya Guinea',
    Paraguay: 'Paraguay',
    Peru: 'Peru',
    Philippines: 'Filippinerna',
    Pitcairn: 'Pitcairn',
    Poland: 'Polen',
    Portugal: 'Portugal',
    PuertoRico: 'Puerto Rico',
    Qatar: 'Qatar',
    Reunion: 'Reunion',
    Romania: 'Rumänien',
    RussianFederation: 'Ryska Federationen',
    Rwanda: 'Rwanda',
    SaintHelena: 'Sankt Helena',
    SaintKittsAndNevis: 'Saint Kitts och Nevis',
    SaintLucia: 'Saint Lucia',
    SaintPierreAndMiquelon: 'Saint Pierre och Miquelon',
    SaintVincentAndTheGrenadines: 'Saint Vincent och Grenadinerna',
    Samoa: 'Samoa',
    SanMarino: 'San Marino',
    SaoTomeAndPrincipe: 'Sao Tomé och Principe',
    SaudiArabia: 'Saudiarabien',
    Senegal: 'Senegal',
    Serbia: 'Serbien',
    Seychelles: 'Seychellerna',
    SierraLeone: 'Sierra Leone',
    Singapore: 'Singapore',
    Slovakia: 'Slovakien',
    Slovenia: 'Slovenien',
    SolomonIslands: 'Salomonöarna',
    Somalia: 'Somalia',
    SouthAfrica: 'Sydafrika',
    SouthGeorgiaAndTheSouthSandwichIslands:
      'South Georgia och The South Sandwich Islands',
    Spain: 'Spanien',
    SriLanka: 'Sri Lanka',
    Sudan: 'Sudan',
    Suriname: 'Surinam',
    SvalbardAndJanMayen: 'Svalbard och Jan Mayen',
    Swaziland: 'Swaziland',
    Sweden: 'Sverige',
    Switzerland: 'Schweiz',
    SyrianArabRepublic: 'Syriska Arabrepubliken',
    Taiwan: 'Taiwan',
    Tajikistan: 'Tadzjikistan',
    'Tanzania,UnitedRepublic of': 'Tanzania, Förenade republiken',
    Thailand: 'Thailand',
    'Timor-leste': 'Östtimor',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    TrinidadAndTobago: 'Trinidad och Tobago',
    Tunisia: 'Tunisien',
    Turkey: 'Turkiet',
    Turkmenistan: 'Turkmenistan',
    TurksAndCaicosIslands: 'Turks- och Caicosöarna',
    Tuvalu: 'Tuvalu',
    Uganda: 'Uganda',
    Ukraine: 'Ukraina',
    UnitedArabEmirates: 'Förenade arabemiraten',
    UnitedKingdom: 'Storbritannien',
    UnitedStates: 'Förenta staterna',
    UnitedStatesMinorOutlyingIslands: 'Förenta staternas mindre öar',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Uzbekistan',
    Vanuatu: 'Vanuatu',
    Venezuela: 'Venezuela',
    VietNam: 'Vietnam',
    VirginIslandsBritish: 'Jungfruöarna, Brittiska',
    VirginIslandsUS: 'Jungfruöarna, U.S.',
    WallisAndFutuna: 'Wallis och Futuna',
    WesternSahara: 'Västra Sahara',
    Yemen: 'Jemen',
    Zambia: 'Zambia',
    Zimbabwe: 'Zimbabwe',
  },
  //Alt texts
  'alt.example-1': 'två självkörande bilar',
  'alt.example-2': 'en mobiltelefon i handen',
  'alt.example-3': 'kvinnans ansikte',
  'alt.defining-ai': 'blandning av ord i anknytning till AI',
  'alt.turing-test': 'en man chattar på en dator med en man och en kvinna',
  'alt.chinese-room': 'det kinesiska rummet experimentet',
  'alt.chicken-crossing': 'roboten i en båt ser på hönan, räven och sädespåsen',
  'alt.a-to-b': 'en mobiltelefon i handen med navigeringsapp på',
  'alt.tic-tac-toe': 'Två barn leker tre-i-rad',
  'alt.self-driving-car': 'en kvinna går framför en självkörande bil',
  'alt.spam-or-ham': 'önskad e-post och skräppost',
  'alt.mnist': 'MNIST-databas',
  'alt.nearest-neighbor': 'två personer gör inköp',
  'alt.nearest-neighbor-graph': 'närmaste granne-klassificeraren',
  'alt.recommendation': 'en man och en kvinna som lyssnar på musik via mobilen',
  'alt.price-real-estate': 'tre olika hus',
  'alt.supervised-learning': 'människan lär roboten',
  'alt.dice': 'tärningar',
  'alt.chess': 'schackbräde',
  'alt.poker': 'spelkort i handen',
  'alt.eyes': 'tre personer med bruna ögon, en person med blåa ögon',
  'alt.search-1': 'en enkel rutt till ett mål',
  'alt.search-2': 'fyra olika rutter till samma mål',
  'alt.chicken-crossing-1': 'starttillståndet i roddbåtsproblemet',
  'alt.chicken-crossing-2': 'första övergången i roddbåtsproblemet',
  'alt.chicken-crossing-3': 'alla övergångar i roddbåtsproblemet',
  'alt.chicken-crossing-4': 'bästa rutten i roddbåtsproblemet',
  'alt.chicken-crossing-5': 'starttillståndet i roddbåtsproblemet',
  'alt.implication-1': 'en man och en kvinna',
  'alt.implication-2': 'ögon',
  'alt.implication-3': 'en övervakningskamera',
  'alt.implication-4': 'en robot som arbetar vid en dator',
  'alt.oddchange': 'sol och moln',
  'alt.brain': 'bild på hjärnan',
  'alt.carstop': 'självkörande bild vid en stoppskylt',
  'alt.gan':
    'en robot som håller i en bild på en katt och en bild på en stoppskylt',
  'alt.hammock': 'en robot fläktar luft på en man i en hängmatta',
  'alt.game-tree-1': 'spelträd 1',
  'alt.game-tree-2': 'spelträd 2',
  'alt.game-tree-3': 'spelträd 3',
  'alt.game-tree-4': 'spelträd 4',
  'alt.bayes-rule-1':
    'bilden föreställer falska positiva och negativa för cancer screening',
  'alt.bayes-rule-2':
    'bilden föreställer falska positiva och negativa för cancer screening',
  'alt.defineai': 'blandning av ord i anknytning till AI',
  'alt.winter': 'en robot sover i snön',
  'alt.terminator': 'en skrämmande robot',
}
